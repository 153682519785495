<template>
  <div class="navbar-container d-flex content align-items-center">
    <!--<b-button v-if="false"-->
              <!--v-ripple.400="$x.ripple.dark" variant="flat-dark" class="btn-icon rounded-circle" @click="isVerticalMenuCollapsed = !isVerticalMenuCollapsed">-->
    <!--</b-button>-->
    <feather-icon class="x-max-xl-none" :icon="isVerticalMenuCollapsed ? 'AlignLeftIcon' : 'AlignRightIcon'" size="22"  @click="isVerticalMenuCollapsed = !isVerticalMenuCollapsed"/>
     <!--Nav Menu Toggler-->
    <ul class="nav navbar-nav d-xl-none">
      <li class="nav-item">
        <b-link
          class="nav-link"
          @click="toggleVerticalMenuActive"
        >
          <feather-icon
            icon="MenuIcon"
            size="22"
          />
        </b-link>
      </li>
    </ul>
    <!-- Left Col -->
    <div  class="bookmark-wrapper align-items-center flex-grow-1 d-none d-lg-flex">
      <app-breadcrumb />
    </div>

    <b-navbar-nav class="nav align-items-center ml-auto">
      <locale v-if="false"/>
      <cart-dropdown v-if="false"/>
      <!-- <search-bar /> -->
      <dashboard />
      <withdrawal />
      <dark-Toggler class="d-none d-lg-block" />
      <!--<notification-dropdown />-->
      <fullscreen/>
      <user-dropdown />
    </b-navbar-nav>
  </div>
</template>

<script>
import {
  BNavbarNav, BLink,
} from 'bootstrap-vue'
import AppBreadcrumb from '@core/layouts/components/AppBreadcrumb.vue'
import Fullscreen from './components/Fullscreen.vue'
import Dashboard from './components/Dashboard.vue'
import Withdrawal from './components/Withdrawal.vue'
import Locale from './components/Locale.vue'
// import SearchBar from './components/SearchBar.vue'
import DarkToggler from './components/DarkToggler.vue'
import CartDropdown from './components/CartDropdown.vue'
// import NotificationDropdown from './components/NotificationDropdown.vue'
import UserDropdown from './components/UserDropdown.vue'
import useAppCustomizer from '../app-customizer/useAppCustomizer'

export default {
  components: {
    BLink,
    // Navbar Components
    BNavbarNav,
    Locale,
    // SearchBar,
    DarkToggler,
    CartDropdown,
    // NotificationDropdown,
    UserDropdown,
    Dashboard,
    Withdrawal,
    AppBreadcrumb,
    Fullscreen,
  },
  props: {
    toggleVerticalMenuActive: {
      type: Function,
      default: () => {},
    },
  },
  setup() {
    const {
      // Vertical Menu
      isVerticalMenuCollapsed,
    } = useAppCustomizer()

    return {
      // Vertical Menu
      isVerticalMenuCollapsed,
    }
  },
}
</script>
